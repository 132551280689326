.ticket-body {
    @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
    /* font-family: 'Ubuntu', sans-serif; */
    font-family: 'Poppins', sans-serif;
    background-color: #e57d32c4;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #1c1c1c;
    display: flex;
    justify-content: center;
    margin: 0;
    overflow: scroll;
}

.ticket-system {
    max-width: 430px;

    .top {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: normal;
            font-size: 1.6em;
            text-align: left;
            margin-left: 20px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
        }

        .printer {
            width: 100%;
            height: 20px;
            border: 5px solid #fff;
            border-radius: 10px;
            box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2);
        }
    }

    .receipts-wrapper {
        overflow: hidden;
        margin-top: -10px;
        padding-bottom: 10px;
    }

    .receipts {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        transform: translateY(-510px);

        animation-duration: 2.5s;
        animation-delay: 500ms;
        animation-name: print;
        animation-fill-mode: forwards;

        .receipt {
            padding: 25px 30px;
            text-align: left;
            min-height: 200px;
            width: 95%;
            background-color: #fff;
            border-radius: 10px 10px 20px 20px;
            box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.2);

            .airliner-logo {
                max-width: 80px;
            }

            .route {
                display: flex;
                justify-content: center; /* Center the content horizontally */
                align-items: center; /* Center the content vertically */
                flex-direction: column; /* Stack the children vertically */
                margin: 30px 0;


                .plane-icon {
                    width: 30px;
                    height: 30px;
                    transform: rotate(90deg);
                }

                h2 {
                    font-weight: 300;
                    font-size: 2.2em;
                    margin: 0;
                }
            }

            .details {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;

                .item {
                    display: flex;
                    flex-direction: column;
                    min-width: 70px;

                    span {
                        font-size: 1em;
                        color: rgba(28, 28, 28, .7);
                        font-weight: 500;
                    }

                    h3 {
                        margin-top: 10px;
                        margin-bottom: 25px;
                        font-size: 1.27em;
                        font-weight: bold;
                    }
                }
            }

            &.qr-code {
                height: 110px;
                min-height: unset;
                position: relative;
                border-radius: 20px 20px 10px 10px;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    background: linear-gradient(to right, #fff 50%, #e57d32 50%);
                    background-size: 22px 4px, 100% 4px;
                    height: 4px;
                    width: 90%;
                    display: block;
                    left: 0;
                    right: 0;
                    top: -1px;
                    position: absolute;
                    margin: auto;
                }

                .qr {
                    width: 70px;
                    height: 70px;
                }

                .description {
                    margin-left: 20px;

                    h2 {
                        margin: 0 0 5px 0;
                        font-weight: 500;
                    }

                    p {
                        margin: 0;
                        font-weight: 400;
                    }

                    h3 {
                        /* font-size: large; */
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@keyframes print {
    0% {
        transform: translateY(-510px)
    }
    35% {
        transform: translateY(-395px);
    }
    70% {
        transform: translateY(-140px);
    }
    100% {
        transform: translateY(0);
    }
}

.logo-watermark {
    position: absolute;
    top: 50%;
    left: 48%;
    right:50%;
    transform: translate(-50%, -50%);
    opacity: 0.4;
    filter: brightness(80%);
    width: 100px;
    height: auto;
    border-radius: 50%;
    -webkit-filter: brightness(80%);
}

.event-title {
    font-style: italic;
    text-align: center;
    font-size: 2.17em;
    margin-top: 15px;

}

.receipts .receipt .route .event-title {
    margin-bottom: 20px;

}

@-webkit-keyframes print {
    0% {
        -webkit-transform: translateY(-510px);
        transform: translateY(-510px);
    }
    35% {
        -webkit-transform: translateY(-395px);
        transform: translateY(-395px);
    }
    70% {
        -webkit-transform: translateY(-140px);
        transform: translateY(-140px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}