.stage-booking {
    border: 2px solid #a5a5a5;
    border-top: inset;
    color: #000;
    display: flex;
    height: 150px;
    margin-top: 100px;
    width: 80%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: xx-large;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
}

.seat-container-booking {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.row-group-booking {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}


.row-gap-booking{
    height: 10px;
    width: 100%;
}




.column-space {
    width: 50px; /* Adjust the width as needed */
    display: inline-block;
}

.row > .chair {
    background-color: #fff;
    border: 1px solid #a99f9f;
    border-radius: 4px;
    box-shadow: 2px 2px 3px #bc9f9f;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    width: 36px;
    align-items: flex-end;
    margin-right: 8px;

}

.row > .grey-seat {
        background-color: lightgrey;
}

.row > .white-seat  {
    background-color: white;
}

.row > .green-seat {
    background-color: lightgreen;
}

.row > .blue-seat {
    background-color: lightblue;
}


.card-booking {
    width: 99rem; /* Adjust width as needed */
    overflow-x: auto; /* Enable horizontal scrollbar */
    margin-top: 20px;
    max-height: 500px;
    overflow-y: auto;

}


.card-content-booking {
    width: max-content; /* Allow content to expand horizontally */
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
}

.popover-header.grey-seat {
    background-color: lightgrey;
    color: black;
}

.popover-header.blue-seat {
    background-color: lightblue;
    color: black;
}

.popover-header.green-seat {
    background-color: lightgreen;
    color: black;
}
.popover-header.white-seat {
    background-color: white;
    color: black;
}

.selected {
    font-weight: bold;
    color: black;
    background: yellow !important; /* Ensure the background color is applied */
}


