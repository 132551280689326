.card-filter {
  background-color: #f9f9f9;
  border-radius: 8px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
}

.cardFilterHeader {
  background-color: #e0e0e0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
}

.gridItem {
  margin-bottom: 10px;
  display: flex;
  font-display: row;
  justify-content: left;
  align-items:last baseline;
}

/* .button {
  background-color: #2196f3;
  margin-top: 45%;
  margin-left: 440%;
  align-self: start;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.button:hover {
  background-color: #0d8af4;
}

.collapsed {
  height: 20px; /* Set your desired collapsed height */
  overflow: hidden;
  transition: height 0.3s ease-in-out; /* Add transition for smooth animation */
}

.expanded {
  height: 40%; /* Allow content to expand to fit */
}