.stage {
    border: 2px solid #a5a5a5;
    border-top: inset;
    color: #000;
    display: flex;
    height: 150px;
    margin-top: 100px;
    width: 90%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: xx-large;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
}


.row-group {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}


.seat {
    background-color: #fff;
    border: 1px solid #a99f9f;
    border-radius: 4px;
    box-shadow: 2px 2px 3px #bc9f9f;
}

.row-label {
    /* background-color: #000; */
    /* color: #fff; */
    font-weight: bolder;
    font-size: large;
}


.row-label, .seat {
    display: flex;
    height: 25px;
    justify-content: center;
    margin-right: 5px;
    width: 20px;
    align-items: flex-end;
}

.row-gap {
    height: 10px;
    width: 100%;
}

.seat-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.card-hall {
    width: 94rem; /* Adjust width as needed */
    overflow-x: auto; /* Enable horizontal scrollbar */
    margin-top: 20px;
    display: flex;
    justify-content: center;
}



.card-content {
    width: max-content; /* Allow content to expand horizontally */
    white-space: nowrap;
}
