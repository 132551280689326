.section {
    margin: 20px;
}

.page {
    text-align: justify;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.content {
    margin-top: 20px;
    text-align: justify;
}

@media only screen and (max-width: 900px) {
    .page {
        padding: 10px;
    }
}
