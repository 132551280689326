.card-statistics {
    border: 1px solid #e7e7e7;
    border-radius: 0.5rem;
    overflow: hidden;

}

.card-header {
    padding: 1rem;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
}

.card-title {
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 20px;
    margin-top: 10px;
    color: gray;

}

.card-text {
    font-size: 20rem;
    color: #888;


}

.statistics-body{
    padding: 1rem;
}

.media {
    display: flex;
    align-items: center;
}

.avatar {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 1rem;
}

.avatar-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-icon {
    width: 18px;
    height: 18px;
}

.media-body {
    display: flex;
    flex-direction: column;
}

.font-weight-bolder {
    font-size:1rem;
}

.font-small-3 {
    font-size: 1rem;
}

.bg-light-primary {
    background-color: #e1f3ff;
    color: #3b82f6;
}

.bg-light-info {
    background-color: #fffbe6; /* Yellow background color */
    color: #d48806; /* Darker yellow text color */
}


.bg-light-danger {
    background-color: #fff5f5;
    color: #ff4343;
}

.bg-light-success {
    background-color: #f6ffed;
    color: #52c41a;
}
