    .modal-container {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        width: 600px;
        max-width: 90vw;
        padding: 20px;
    }
    
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .modal-body {
        display: flex;
        flex-direction: column;
    }
    
    .input-container {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 20px;
        position: relative;
    }
    
    .input-container input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    
    .file-input-container {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        width: 100%;
    }
    
    .file-input-container label {
        font-size: 14px;
        margin-bottom: 5px;
        width: 100%;
    }
    
    .input-container button {
        display: flex;
        justify-content: flex-end;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 100%;
        padding: 8px 16px;
    }
    
    .input-container button:hover {
        background-color: #0069d9;
    }

    .right {
        /* margin-left: 20px; */
        display: flex;
        justify-content: flex-end;
    }

    .file-input {
        cursor: pointer;
    }

    #outlined-adornment-weight{
        cursor: pointer;
    }