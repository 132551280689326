@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding-top: 20px;
    text-align: center;
    background-color: aliceblue;
    padding: 10px; /* Add padding for better spacing on small screens */
}

.message {
    margin-top: 100px;
    margin-bottom: 20px;
    font-family: Roboto Thin;
    font-style: normal; /* Regular font style */
    font-weight: 700; /* Bold font weight */
    color: #444; /* Example of different text color */
}

.message h3 {
    font-size: 1.6rem;
    color: inherit; /* Use the inherited color */
}

.image {
    margin-top: 120px;
    width: 80%; /* Make the image responsive */
    max-width: 600px; /* Set a max-width for larger screens */
    height: auto; /* Maintain the aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .message h3 {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }

    .image {
        width: 100%; /* Full width for very small screens */
        max-width: 300px; /* Adjust max-width for smaller screens */
    }
}
